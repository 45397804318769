/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import 'theme/variables.scss';
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/dynamic-class.scss";
@import "./app/_scrollbar/scrollbar.scss";
@import "./feather.css";

* {
	font-family: "Noto Sans", sans-serif !important;
}

// font css
@font-face {
	font-family: "CenturyGothic-Bold";
	src: url("./assets/fonts/gothicb.ttf");
	font-weight: 700;
	font-style: normal;
	font-display: swap
}

// for ios safe area issue
html {
	position: relative !important;
	top: env(safe-area-inset-top) !important;
	bottom: env(safe-area-inset-bottom) !important;
}

body {
	height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
}

ion-header ion-toolbar:first-of-type {
	padding-top: 0 !important;
	background: var(--ion-color-primary-contrast);
}

// for ios safe area issue

.password-icon-arabic {
	color: var(--ion-color-secondary-black);
	width: 1.375rem;
	position: absolute;
	left: 0.625rem;
	top: 1.25rem;
	font-size: 1.5rem;
	z-index: 9999;
	cursor: pointer;
}

[dir="rtl"] {
	.text-right-rtl {
		text-align: right !important;
	}

	.text-left {
		text-align: right;
	}

	.text-right {
		text-align: left;
	}

	.arrow-custom-btn-rtl {
		right: unset !important;
		left: 0 !important;
		transform: rotate(180deg) !important;
		padding-top: 0 !important;
	}

	.in-english {
		display: none !important;
	}

	.in-arabic {
		display: block !important;
	}

	.modal-close {
		position: absolute;
		left: 0.9375rem;
		right: unset !important;
		top: 0.9375rem;
		cursor: pointer;
	}
}

.title-block {
	display: flex;
	padding: 0 0.5rem;

	ion-title {
		text-align: center;
		position: relative;
		left: -4vw;
		font-size: 1.125rem;
		font-weight: 700;
		color: var(--ion-black-primary);
		text-transform: capitalize;
	}
}

.checked-price {
	text-decoration: line-through;
}

.desktop-visibility {
	display: block;
}

.mobile-visibility {
	display: none;
}

.desktop-visibility-in-flex {
	display: flex;
}

.mobile-visibility-in-flex {
	display: none;
}

.desktop-searchbar-ltr {
	.searchbar-search-icon.sc-ion-searchbar-md {
		right: -1px !important;
		left: auto !important;
		top: 0 !important;
		height: 46.5px !important;
		width: 46px !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		height: 46px;
		width: 98%;
		padding-inline-start: 1.375rem;
		padding-inline-end: 4.375rem;
		--placeholder-color: var(--ion-grey1);
		--box-shadow: none;
		border: 1px solid var(--ion-grey4);
		background: var(--ion-grey4);
		font-weight: normal;
		font-size: 0.875rem;

		@media screen and (max-width: 768px) {
			width: 84% !important;
		}

		@media screen and (min-width: 1439px) {
			width: 95%;
		}

		@media screen and (max-width: 1200px) {
			width: 93%;
		}

	}

	.searchbar-clear-button.sc-ion-searchbar-md {
		right: 5.125rem;

		@media screen and (max-width: 768px) {
			right: 4.125rem;
		}
	}
}

.desktop-searchbar-rtl {
	.searchbar-search-icon.sc-ion-searchbar-md {
		right: auto !important;
		left: 0 !important;
		top: 0 !important;
		height: 45.5px !important;
		width: 46px !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		height: 46px;
		padding-inline-start: 1.375rem;
		padding-inline-end: 4.375rem;
		--placeholder-color: var(--ion-grey1);
		--box-shadow: none;
		border: 1px solid var(--ion-divider);
	}

	.searchbar-clear-button.sc-ion-searchbar-md {
		left: 3.125rem !important;

		@media screen and (max-width: 768px) {
			right: 4.125rem;
		}
	}
}

.mobile-searchbar {
	.searchbar-search-icon.sc-ion-searchbar-md {
		height: 1.125rem !important;
		width: 1.125rem !important;
		top: 0.8125rem !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		padding-inline-start: 2.625rem;
		--placeholder-color: var(--ion-grey1);
		--box-shadow: none;
		border: 1px solid var(--ion-divider);
		height: 42px;
	}
}

// add style on focus in searchbar
.searchbar-input.sc-ion-searchbar-md:focus {
	border-width: 2px;
}

// add pointer on search icon
.searchbar-search-icon.sc-ion-searchbar-md {
	cursor: pointer;
	pointer-events: unset;
}

.filter-accordion {
	.ion-accordion-toggle-icon {
		display: none !important;
	}

	.iconColor {
		color: var(--ion-app-primary) !important;
		pointer-events: none;
	}
}

.red-btn {
	background: var(--ion-app-primary);
	border-radius: 2px;
}

ion-header {
	--ion-statusbar-padding: 1.875rem;
}

.notify-me {
	text-align: left;
}

[dir="rtl"] .notify-me {
	text-align: right;
}

ion-content {
	--overflow: overlay;
}

.search-content {
	--offset-bottom: 0 !important;
	--overflow: auto !important;
	overflow: overlay;
}

.notification-ion-content,
.orders-ion-content {
	--overflow: auto !important;
	overflow: overlay;
}

.quantity-input {
	width: 50%;
	text-align: center;

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: textfield;
		margin: 0;
	}
}

.ion-page {
	justify-content: flex-start !important;
	background: white;
	--background: white;
}

.common-text-area {
	ion-item {
		border: 1px solid var(--ion-divider);
		--padding-start: 0.875rem;
		--highlight-color-focused: #103e53;
		--min-height: 58px;

		ion-textarea {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
			margin-top: 0;
			line-height: 1rem;
		}

		ion-label {
			padding-top: 0.125rem;

			.required-star {
				color: var(--ion-app-primary);
			}
		}

		.label-floating.sc-ion-label-md-h {
			color: var(--ion-grey1) !important;
			padding: 0;
			position: relative;
			bottom: 0.4375rem;
		}
	}
}

ion-spinner {
	--color: var(--ion-app-primary);
}

.common-input {
	ion-item {
		border: 1px solid var(--ion-divider);
		--padding-start: 0.875rem;
		--highlight-color-focused: #103e53;
		// --min-height: 58px;
		// height: 3.75rem;

		ion-input {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
			// margin-top: 0.3125rem;
		}

		ion-textarea {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
		}

		ion-label {
			padding-top: 0.125rem;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			cursor: default;

			.required-star {
				color: var(--ion-app-primary);
			}
		}

		.label-floating.sc-ion-label-md-h {
			color: var(--ion-grey1) !important;
			padding: 0;
			line-height: 1.4rem;
		}

		.native-input.sc-ion-input-md {
			padding-top: 0;
			padding-bottom: 0;
		}

		.countrycode-label {
			position: absolute;
			top: 1.75rem;
		}

		img {
			margin: 0.125rem 0.9375rem 0.125rem 0 !important;
		}
	}

	ion-item.mobile-phone {
		.native-input.sc-ion-input-md {
			padding-bottom: 0;
		}
	}

	.custom-ion-item {
		--padding-start: 0;
		--inner-padding-end: 0;
	}

	.mobile-phone {
		img {
			margin-right: 0.5625rem !important;
		}
	}
}

input[type="number"] {
	-moz-appearance: textfield !important;
}

.common-input-textarea {
	ion-item {
		border: 1px solid var(--ion-divider);
		--padding-start: 0.875rem;
		--highlight-color-focused: #103e53;
		--min-height: 58px;
		height: 6.75rem;

		ion-input {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
			margin-top: 0.3125rem;
		}

		ion-textarea {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
		}

		ion-label {
			padding-top: 0.125rem;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			cursor: default;

			.required-star {
				color: var(--ion-app-primary);
			}
		}

		.label-floating.sc-ion-label-md-h {
			color: var(--ion-grey1) !important;
			padding: 0;
			line-height: 1.4rem;
		}

		.native-input.sc-ion-input-md {
			padding-top: 0;
		}

		.countrycode-label {
			position: absolute;
			top: 1.6875rem;
		}

		img {
			margin: 0.125rem 0.9375rem 0.125rem 0 !important;
		}
	}

	.mobile-phone {
		img {
			margin-right: 0.5625rem !important;
		}
	}
}

[dir="rtl"] .common-input {
	ion-item {
		img {
			margin: 0.125rem 0 0.125rem 0.9375rem !important;
		}
	}
}

[dir="rtl"] .common-input-textarea {
	ion-item {
		img {
			margin: 0.125rem 0 0.125rem 0.9375rem !important;
		}
	}
}

.input-clear-icon {
	cursor: pointer;
	margin: 0 !important;
	width: 1.5rem;
}

.return-order-header,
.searchbar-container,
.return-order-footer,
.return-request-header,
.return-request-footer {
	display: none;
}

.search-div {
	align-self: center;

	ion-item {
		border: 1px solid var(--ion-color-primary-border);
		font-size: 0.75rem;
		border-radius: 0;
		height: 46px;
		--min-height: 1.25rem;
		width: 271px;

		.sc-ion-input-md-h {
			font-size: 0.835rem;
		}
	}
}

.section-block {
	display: flex;
	align-items: center;
}

.orders-listing,
.return-orders-listing,
.return-request,
.upper-part-watchlist {
	.listing-header {
		display: flex;
		justify-content: space-between;
		padding: 0.3125rem 0 1.5625rem 0;

		.title-div {
			font-size: 2.625rem;
			font-weight: bold;
			color: var(--ion-black-primary);
			text-transform: capitalize;

		}

		.search-and-filter {
			width: 100%;
			display: flex;
			justify-content: flex-end;

			.filter-div {
				display: flex;

				.filter-icon {
					display: flex;
					align-items: center;
					padding: 0 0.5625rem;
					cursor: pointer;
				}

				.close-icon {
					display: flex;
					align-items: center;
					padding: 0 0.3125rem;
					cursor: pointer;
					align-self: center;

					img {
						width: 1.5rem;
					}
				}

				.filter-label {
					cursor: pointer;
					font-weight: 600;
					font-size: 0.875rem;
					line-height: 1.0625rem;
					display: flex;
					align-items: center;
					letter-spacing: 0.3px;
					text-transform: uppercase;
					color: var(--ion-app-primary);
				}

				.close-label {
					color: var(--ion-black-primary) !important;
				}
			}
		}

		.search-and-filter {
			display: flex;
			justify-content: flex-end;

			.filter-div {
				display: flex;

				.filter-icon {
					display: flex;
					align-items: center;
					padding: 0 0.5625rem;
					cursor: pointer;
				}

				.close-icon {
					display: flex;
					align-items: center;
					padding: 0 0.3125rem;
					cursor: pointer;
					align-self: center;

					img {
						width: 1.5rem;
					}
				}
			}
		}
	}

	.listing-table {
		padding: 0 0 1.25rem;
		overflow: overlay;

		.table {
			width: 100%;

			.column-heading {
				font-weight: 400;
				font-size: 0.75rem;
				line-height: 1rem;
				text-transform: uppercase;
				color: var(--ion-grey1);
				padding: 0.75rem 0.5625rem 0.75rem 0;
			}

			.order-id-col {
				font-weight: bold !important;
				max-width: 160px;
				width: 150px;
			}

			.date-col {
				max-width: 170px;
				width: 160px;
			}

			.status-col {
				max-width: 110px;
				width: 100px;
			}

			.ship-to-col {
				min-width: 280px;
				max-width: 300px;
			}

			.order-total-col {
				max-width: 130px;
				width: 7.5rem;
			}

			.download-col {
				min-width: 50px;
				max-width: 3.75rem;
			}

			.arrow-col {
				min-width: 35px;
				max-width: 40px;
			}

			.table-body {
				font-size: 1rem;
				letter-spacing: 0;
				color: var(--ion-black-primary);
				opacity: 1;

				.table-row {
					border-bottom: 1px solid var(--ion-divider);
					cursor: pointer;

					td {
						padding: 0.4375rem 0.5625rem 0.1875rem 0;
						font-weight: 400;
						font-size: 1rem;
						line-height: 1.1875rem;
						color: var(--ion-black-primary);
					}

					.right-arrow {
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}

	.mobile-view-listing {
		.order-container {
			.order-card {
				background: #ffffff;
				border: 1px solid var(--ion-divider);
				border-radius: 2px;
				margin: 0 0 0.625rem;

				.order-card-col {
					padding-top: 0.9375rem;
				}

				.grid-label {
					font-weight: 500;
					font-size: 0.6875rem;
					line-height: 0.8125rem;
					text-transform: uppercase;
					color: var(--ion-grey1);
				}

				.grid-values {
					padding-top: 0.1875rem;
					font-weight: 500;
					font-size: 1rem;
					line-height: 1.1875rem;
					color: #103e53;
				}
			}

			.mobile-right-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}


.orders-listing .listing-table .table .table-body .table-row:last-child,
.return-orders-listing .listing-table .table .table-body .table-row:last-child,
.return-request .listing-table .table .table-body .table-row:last-child,
.upper-part-watchlist .listing-table .table .table-body .table-row:last-child {
	border-bottom: none;
	/* Remove the border for the last row */
}

.divider-line {
	height: 1px;
	width: 100%;
	background: var(--ion-divider);
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.status-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 40px;
}

.mobile-listing-grid {
	overflow: overlay;
	padding: 0 1.25rem;
	min-height: 587px;
}

.header-ios ion-toolbar:last-of-type {
	--border-width: 0 0 0 0 !important;
}

.return-orders-listing {
	overflow: overlay;
	overflow-x: hidden;
}

// Date popover ------------------------->
#datePopover {
	&::part(content) {
		top: 29vh;
		margin: 0 auto;
		left: 0 !important;
		right: 0 !important;
		transform-origin: left top;
		min-width: fit-content;
		max-width: fit-content;
		display: contents;

	}
}

.from-date-div,
.to-date-div {
	display: flex;
	align-items: center;
	width: 100%;
}

.from-date-label.RTL,
.to-date-label.RTL {
	margin-left: 0.625rem;
}

.from-date-label.LTR,
.to-date-label.LTR {
	margin-right: 0.625rem;
}

.from-date-label,
.to-date-label {
	text-transform: capitalize;

}

.from-date-class,
.to-date-class {
	background: var(--ion-color-primary-white) 0% 0% no-repeat padding-box;
	border: 1px solid var(--ion-divider);
	opacity: 1;
	height: 44px;
	width: 100%;
	--min-height: 44px;

	ion-icon {
		width: 1.125rem;
		margin: auto;
	}

	ion-input {
		--padding-bottom: 0;
		--padding-top: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.close-filter-icon {
	width: 1.75rem;
	height: 1.75rem;
	margin-right: 0.4375rem;
	cursor: pointer;

	img {
		vertical-align: bottom;
	}
}

.filter-buttons {
	display: flex;
	justify-content: flex-end;
	column-gap: 0.625rem;
	row-gap: 0.625rem;
}

.filter-apply-button {
	background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
	opacity: 1;
	font-size: 0.875rem;
	font-weight: bold;
	letter-spacing: 0;
	color: var(--ion-color-primary-contrast);
	text-transform: uppercase;
	width: 92px;
	height: 38px;
}

.listing-header-btn,
.listing-footer-btn {
	height: 46px;
	background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
	opacity: 1;
	font-size: 0.95rem;
	padding: 0.8125rem 2rem;
	font-weight: bold;
	letter-spacing: 0;
	color: var(--ion-color-primary-contrast);
	text-transform: uppercase;
	opacity: 1;
}

.listing-footer-btn {
	width: 100%;
}

.return-order-listing-footer {
	padding: 0.625rem 1.25rem 1.875rem;
	width: 100%;
	box-shadow: 2px 0 6px #00000029;
}

.custom-select-dd {
	&::part(content) {
		border-radius: 0;
		max-height: 300px;
	}

	.item-radio-checked {
		--background: var(--ion-app-primary);
		--background-focused: var(--ion-app-primary);
		--background-focused-opacity: 0.2;
		--background-hover: var(--ion-app-primary);
		--background-hover-opacity: 0.12;
		--color: #fff;
	}

	ion-list {
		padding: 0;
	}

	ion-item.sc-ion-select-popover-md {
		text-transform: capitalize;
	}
}

.per-page-dd {
	&::part(content) {
		width: 5.625rem;
	}
}

.status-dropdown {
	background: var(--ion-color-primary-contrast) 0% 0% no-repeat padding-box;
	border: 1px solid var(--ion-divider);
	opacity: 1;
	height: 44px;
	display: flex;
	--ripple-color: transparent;
	--background-activated: transparent;
	padding: 0;

	ion-item {
		--background-hover: transparent;
		--ripple-color: transparent;
		width: 100%;
		--min-height: 44px;
		--padding-start: 0;
		--padding-end: 0;
		--inner-padding-end: 0;
		--inner-padding-start: 0;

		ion-select {
			width: 100%;
			--padding-top: 0;
			--padding-bottom: 0;
			--background-hover: transparent;
			--ripple-color: transparent;
			--min-height: 44px !important;
			--max-height: 44px;
			--height: 44px;
			height: 44px;
			max-height: 44px;
			min-height: 44px;
			padding: 0 16px;
		}
	}

	.item-interactive.item-has-focus {
		border: none !important;
	}

	ion-input {
		--padding-bottom: 0;
		--padding-top: 0;
	}
}

.status-btn,
.pending,
.shipped,
.canceled {
	min-width: 110px;
	width: auto;
	font-weight: 500;
	font-size: 0.875rem;
	height: 2.125rem;
	border-radius: 4px;
	text-transform: none;
	letter-spacing: 0;
	--padding-start: 0.4375rem;
	--padding-end: 0.4375rem;
}

.order-status-btn {
	min-width: 120px;
	font-weight: 500;
	font-size: 0.75rem !important;
	height: 2.125rem;
	border-radius: 4px;
	--border-radius: 4px !important;
	text-transform: none;
	letter-spacing: 0;
	--padding-start: 0.4375rem;
	--padding-end: 0.4375rem;

	@media screen and (max-width: 350px) {
		min-width: 120px !important;
	}

	@media screen and (max-width: 768px) {
		font-size: 0.9625rem !important;
	}

	ion-button {
		border-radius: 4px !important;
		--border-radius: 4px !important;
	}

}

.pending,
.under-review {
	background: rgba(109, 207, 246, 0.2);
	color: var(--ion-app-blue);
}

.shipped,
.returned {
	background: rgba(172, 219, 97, 0.25);
	color: var(--ion-app-green);
}

.canceled {
	background: rgba(233, 75, 75, 0.1);
	color: var(--ion-app-primary);
}

.shipping,
.partially-shipped {
	background: rgba(255, 121, 23, 0.17);
	color: var(--ion-app-orange);
}

.reject {
	background: var(--ion-btn-reject);
	color: var(--ion-app-orange);
}

.order-card {
	.status-btn {
		width: auto;
		font-weight: 500;
		font-size: 0.875rem;
		line-height: 0.875rem;
		text-transform: none;
		letter-spacing: 0;
	}
}

.justify-end {
	justify-content: flex-end;
}

.justify-start {
	justify-content: flex-start;
}

.justify-space-between {
	justify-content: space-between;
}

.search-div {
	margin-right: 1.25rem;
	margin-left: 1.25rem;
	align-self: center;

	input {
		border: 1px solid var(--ion-black-primary);
		font-size: 0.75rem;
		padding: 0.1875rem 0.625rem;
		border-radius: 0;
	}
}

.search-icon {
	align-self: center;
	margin-top: 0.25rem;
	cursor: pointer;
}

.printer-icon {
	align-self: center;
	margin-top: 0.25rem;
	cursor: pointer;
}

.pagination-wrapper {
	max-width: 1400px;
	margin: auto;
	text-align: center;
}

.pagination-div {
	align-items: center;
}

.pagination-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin: 1.25rem 0;

	&-tile {
		width: 33%;
		padding: 0;
	}
}

.perpage-dropdown {
	display: flex;
	align-items: center;

	ion-item {
		font-size: 0.875rem;
		font-weight: 600;
		letter-spacing: 0;
		color: var(--ion-black-primary);
		opacity: 1;
	}
}

.perpage-title {
	font-size: 0.875rem;
	letter-spacing: 0;
	color: var(--ion-black-primary);
	opacity: 1;
}

ion-select::part(icon) {
	opacity: 1;
	color: transparent;
	background: url("./assets/icon/down-arrow-dark.svg") no-repeat center;
}

.pagination-col {
	text-align: left;
}

.order-id {
	font-weight: bold;
	font-size: 1rem;
	letter-spacing: 0 !important;
	color: var(--ion-black-primary) !important;
	opacity: 1 !important;
}

.download-arrow {
	display: flex;
	align-items: center;
}

#addressModal {
	app-address {
		height: auto !important;

		@media screen and (max-width: 768px) {
			height: 100% !important;
		}
	}
}

#termsWebModal {
	height: auto !important;

	@media screen and (max-width: 768px) {
		height: 100% !important;
	}
}

#offerDialog {
	app-offer-dialog {
		height: auto !important;

		@media screen and (max-width: 768px) {
			height: 100% !important;
		}
	}
}

#returnOrderModal {
	.ion-page {
		justify-content: unset !important;
	}
}

// custom grid
.custom-grid {
	padding-left: 0;
	padding-right: 0;

	ion-row {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 4.6875rem 40px;

		ion-col {
			width: 100% !important;
			max-width: 100% !important;
			padding-left: 0;
			padding-right: 0;
			padding-inline-start: 0;
			padding-inline-end: 0;
		}
	}
}

.register-Modal,
.select-variant-modal {
	--height: 1119px;
	--border-radius: 0;
}

.cart-Modal {
	--height: 100%;
	--width: 400px;
	--min-height: 190px;
	--max-height: 517px;

	ion-content {
		--overflow: hidden;
		overflow: hidden;
	}

	// ion-modal .ion-page:not(ion-nav .ion-page),
	// .ion-page {
	//     position: absolute !important;
	// }
}

.cart-Modal::part(content) {
	top: 4.6875rem !important;
	position: absolute !important;
	right: 4.375rem !important;
	left: unset !important;
	box-shadow: 0 1.25rem 7.5rem #00000026;
	z-index: 99;
}

// sample modal
.sample-modal {
	.ion-page {
		padding: 2.125rem 3rem;
		font-size: 1rem;
		color: var(--ion-black-primary);

		.modal-close {
			position: absolute;
			top: 0.375rem;
			right: 0.375rem;
			cursor: pointer;
		}

		.upper-part {
			text-align: left;
			margin-bottom: 2.1875rem;

			.heading {
				font-weight: 600;
				font-size: 1.75rem;
				padding-bottom: 0.25rem;
			}

			.text {
				color: var(--ion-grey1);
				font-size: 1rem;
			}
		}

		.detail-section {
			border-bottom: 1px solid #ddd;
			padding-bottom: 2.1875rem;
			margin-bottom: 1.0625rem;
			text-align: left;

			.image-block-part {
				display: flex;
				justify-content: space-between;

				.left-part {
					display: flex;

					.product-image {
						width: 100px;
						height: 100px;
						object-fit: fill;
					}
				}

				.details {
					display: flex;
					flex-direction: column;

					.product {
						font-size: 1.12rem;
						font-weight: bold;
						margin-bottom: 0.75rem;
					}

					.options-block {
						font-size: 0.75em;
						margin-bottom: 0.375rem;

						.name {
							color: var(--ion-grey1);
						}
					}
				}

				.price {
					font-size: 1.12rem;
					font-weight: bold;
					margin-left: 0.1875rem;
				}
			}

			.note {
				color: var(--ion-grey1);
				font-size: 0.75em;
				margin-top: 1.5625rem;
				display: inline-block;
			}
		}

		.bottom-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.cancel {
				cursor: pointer;
			}

			ion-button {
				--background: var(--ion-app-primary);
				width: 164px;
				height: 46px;
			}
		}
	}
}

#sampleModal {
	--height: 450px;
	--width: 600px;
}

.location-popover {
	ion-select-popover {
		--offset-bottom: auto !important;
		--overflow: hidden;
		overflow: overlay;
		max-height: 200px;

		&::-webkit-scrollbar {
			width: 0.625rem;
		}

		&::-webkit-scrollbar:horizontal {
			height: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--ion-divider);
			border-radius: 0.625rem;
		}

		&::-webkit-scrollbar-track {
			background-color: #ffffff;
			border-radius: 0.625rem;
		}
	}
}

// sample modal for mobile
.sample-modal-mobile {
	.ion-page {
		font-size: 1rem;
		color: var(--ion-black-primary);

		.modal-close {
			position: absolute;
			top: 1rem;
			right: 0.625rem;
			cursor: pointer;

			img {
				width: 2rem;
			}
		}

		.horizontal-line {
			border-bottom: 1px solid var(--ion-divider);
			margin: 1.25rem 0;
		}

		.upper-part {
			text-align: left;

			.heading {
				font-weight: bold;
				font-size: 1.12rem;
				text-align: center;
				color: var(--ion-black-primary);
			}

			.text {
				color: var(--ion-grey1);
				font-size: 1rem;
			}
		}

		.detail-section {
			margin: 0 1.25rem 1.875rem 1.25rem;
			text-align: left;

			.note-div {
				display: flex;
				margin-top: 1.5625rem;
				align-items: center;
				justify-content: space-between;

				.price {
					margin-left: 0.625rem;
					font-weight: bold;
					font-size: 1.12rem;
				}
			}

			.image-block-part {
				display: flex;
				justify-content: space-between;

				.left-part {
					display: flex;
					position: relative;

					.sample-tag {
						position: absolute;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 70px;
						height: 19px;
						font-size: 0.625rem;
						font-weight: bold;
						color: var(--ion-grey1);
						background: var(--ion-app-yellow);
					}

					.product-image {
						width: 100px;
						height: 100px;
						object-fit: fill;
					}
				}

				.details {
					display: flex;
					flex-direction: column;
					text-align: left;

					.product {
						font-size: 1.12rem;
						font-weight: bold;
						margin-bottom: 0.75rem;
					}

					.options-block {
						font-size: 0.75em;
						margin-bottom: 0.22rem;

						.name {
							color: var(--ion-grey1);
						}

						.value {
							color: var(--ion-gray3);
							font-weight: 500;
						}
					}

					.estimated-opt {
						background: var(--ion-divider);
					}
				}
			}

			.note {
				color: var(--ion-grey1);
				font-size: 0.75em;
				display: inline-block;
			}
		}

		.bottom-container {
			.cancel {
				cursor: pointer;
			}

			ion-button {
				--background: var(--ion-app-primary);
				width: 98%;
				height: 48px;
				font-size: 0.95rem;
				font-weight: bold;

				.cart-icon {
					margin: 0.625rem;
				}
			}
		}
	}

	// &::part(content) {
	//     --height: 400px;
	//     position: absolute;
	//     bottom: 0;
	// }
}

ion-button {
	--border-radius: 0;
	--box-shadow: none !important;
	--ripple-color: transparent !important;
	--background-activated: transparent !important;
	--background-focused: transparent !important;
}

[dir="rtl"] .cart-Modal::part(content) {
	direction: rtl;
	top: 4.6875rem !important;
	position: absolute !important;
	left: 5.625rem !important;
	right: unset !important;
	box-shadow: 0 1.25rem 7.5rem #00000026;
}

.company-registration {
	ion-label {
		margin-bottom: 0 !important;
	}

	ion-select {
		--placeholder-color: var(--ion-grey1) !important;
		--placeholder-opacity: 1 !important;
	}
}

#twoFaModal {
	--height: 460px;
	--width: 560px;
	align-items: baseline;
	padding-top: 5.3125rem;
}

#sortByModal {
	--height: 335px;
	--width: 560px;
	align-items: flex-end;
}

#filterByModal {
	--height: 324px;
	align-items: flex-end;
}

#orderFilterByModal {
	--height: 360px;
	align-items: flex-end;
}

#designAndColor {
	--height: auto;
	--max-height: 622px;
	align-items: flex-end;
}

#addEditAddressModal {
	--height: 480px;
	--width: 600px;
	align-items: baseline;
	padding-top: 5.3125rem;
}

#forgotPassword,
#twoFaModal_Account,
#changePassword {
	--height: auto;
	--width: 600px;
	align-items: baseline;
	padding-top: 5.3125rem;
}

@media screen and (max-width: 768px) {
	.custom-textarea {
		min-height: 119px;

		textarea {
			overflow: auto !important;
		}
	}

	ion-content {
		--offset-bottom: 0px !important;
		--overflow: auto !important;
		// overflow: auto !important;

		&::-webkit-scrollbar {
			width: unset !important;
		}

		&::-webkit-scrollbar:horizontal {
			height: unset !important;
		}

		&::-webkit-scrollbar-thumb {
			background-color: unset !important;
			border-radius: unset !important;
		}

		&::-webkit-scrollbar-track {
			background-color: unset !important;
			border-radius: unset !important;
		}
	}

	#changePassword,
	#addReviewModal,
	#notifyModal {
		--height: auto;
		align-items: flex-end;
		padding-top: 0;
		width: 100% !important;
		--width: 100% !important;
	}

	.pagination-wrapper {
		display: none !important;
	}

	.listing-header {
		display: none !important;
	}

	.return-order-header,
	.searchbar-container {
		display: block;
	}

	.return-order-footer,
	.return-request-footer {
		display: flex;
	}

	.countrycode-label {
		position: absolute !important;
		top: 1.45rem !important;
		bottom: unset !important;
		left: 3rem !important;
	}

	.countrycode-label-edit {
		position: absolute !important;
		top: 0.8rem !important;
		bottom: unset !important;
		left: 2.6rem !important;
	}
}

#forgotPassword {
	--max-width: 560px;
}

.changePassword,
.forgotPassword,
.addEditAddressModal,
.offerDialog {
	--backdrop-opacity: 0.6 !important;
	background-color: black;
	--width: auto !important;
	--height: 296px !important;
	--border-radius: 0.9375rem !important;
}

#selectVariantModal {
	--backdrop-opacity: 0.6 !important;
	--width: 60% !important;
	--height: 100%;
	--max-height: 585px;
}

.twoFaModal {
	--backdrop-opacity: 0.6 !important;
}

.cancel-product-mobile-popup {
	--backdrop-opacity: 0.6 !important;
	align-items: flex-end;
	--height: fit-content !important;
}

#twoFaModal_Account::part(backdrop),
#loginModal::part(backdrop),
#addressModal::part(backdrop),
#offerDialog::part(backdrop),
#changePassword::part(backdrop),
#addEditAddressModal::part(backdrop),
#addEditPaymentCardModal::part(backdrop),
#forgotPassword::part(backdrop),
#registerModal::part(backdrop),
#selectVariantModal::part(backdrop),
#termsWebModal::part(backdrop) {
	--backdrop-opacity: 0.6 !important;
	background-color: black;
	--border-radius: 0;
}

#cartModal::part(backdrop) {
	--backdrop-opacity: 1 !important;
	background-color: transparent;
}

.twoFaModal .ion-page,
.changePassword .ion-page,
.forgotPassword .ion-page,
.addEditAddressModal .ion-page,
.addEditPaymentCardModal .ion-page {
	justify-content: normal !important;
}

#addEditAddressContent::part(scroll) {
	overflow: overlay !important;
	--overflow: overlay !important;
}

.filterby-select::part(icon) {
	display: none;
}

.filterby-select::part(text) {
	display: none;
}

.mobile-view-listing {
	display: none;
}

.mobile-user-block-ltr {
	display: none;
}

.mobile-user-block-rtl {
	display: none;
}

.rotate-arrow {
	transform: rotate(180deg);
}

ion-title {
	text-transform: capitalize;

}

//header style for mobile
.custom-header {
	ion-title {
		font-size: 28px;
		text-transform: capitalize;

	}

	&::after {
		background-image: none !important;
	}
}

// content side padding
.content-side-padding {
	padding-left: 4.4375rem;
	padding-right: 4.4375rem;
}

// my wishlist style
.my-wishlist-page {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 63px 20px;
}

.upper-part-watchlist {
	display: flex;
	font-size: 1rem;
	flex-direction: column;

	.heading {
		font-weight: 700;
		font-size: 2.6rem;
	}

	.select-btn-block {
		display: flex;
		padding-top: 1.5625rem;
		padding-bottom: 2.875rem;
		justify-content: space-between;


		.select-block {
			display: flex;
			align-items: center;
			width: 100%;

			span {
				font-size: 0.87em;
				font-weight: 600;
				color: var(--ion-black-primary);
			}

			ion-select {
				border: 1px solid #d5dadc;
				padding: 0.5625rem 0.3125rem;
				width: 100%;
			}
		}

		.add-cart-button {
			ion-button {
				--background: var(--ion-app-primary);
				--box-shadow: none;
				width: 225px;
				height: 3.375rem;
				--border-radius: 0;
				font-weight: 700;
				font-size: 1rem;
				line-height: 1.375rem;
				text-transform: uppercase;
				position: relative;
				margin-right: 0;

				.cart-icon {
					position: absolute;
					left: 0;
				}
			}
		}
	}
}

// top-slider
.top-slider {
	.slides {
		margin-top: 0 !important;
		margin-bottom: 0 !important;

		swiper-slide {
			.slide-content {
				.image-section {
					img {
						display: none !important;
					}
				}
			}
		}
	}

	.swiper-container-horizontal>.swiper-pagination-bullets {
		left: 0;
		height: 1.25rem;
		top: 0;
		display: flex;

		.swiper-pagination-bullet {
			width: 100%;
			height: 0.375rem;
			display: inline-block;
			background: none;
			opacity: 0 !important;
			position: relative;
			margin: 0 0.5rem 0 0 !important;
			border-radius: 0 !important;
		}

		.swiper-pagination-bullet-active {
			opacity: 1 !important;
			background: var(--ion-app-primary) !important;
		}
	}
}

.middle-banner {
	.swiper-pagination-bullet {
		width: 45px;
		height: 4px;
		display: inline-block;
		background: none;
		opacity: 0.4 !important;
		background: var(--ion-color-medium) !important;
		position: relative;
		margin: 0 0.5rem 0 0 !important;
		border-radius: 0 !important;
	}

	.swiper-pagination-bullet-active {
		opacity: 1 !important;
		background: var(--ion-app-primary) !important;
	}
}

.product-slider-container {
	.swiper-pagination-bullet {
		opacity: 1 !important;
		background: var(--ion-color-primary-contrast) !important;
	}

	.swiper-pagination-bullet-active {
		opacity: 1 !important;
		background: var(--ion-black-primary) !important;
	}
}

.categoryContent {
	--overflow: hidden !important;
}

ion-checkbox {
	--background-checked: var(--ion-color-primary-white);
	--border-color: var(--ion-color-primary-border);
	--border-color-checked: var(--ion-color-primary-border);
	--checkmark-color: var(--ion-color-primary-contrast);
	--size: 1.25rem;
	--border-width: 1px;
	--border-radius: 0;
}


//Dynamic about us css
.image-block {
	display: flex;
	align-items: center;
	margin: 3.125rem 0 3.125rem;

	.content-section {
		width: 50%;
	}

	.image-section {
		width: 50%;

		img {
			width: 90%;
		}
	}
}

.about-us-section {
	background: var(--ion-light-grey);
	padding: 4.375rem 4.375rem 2.5rem;
	margin: 1.25rem 0 3.125rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1.25rem;

	.block {
		display: flex;
		flex-direction: column;
		align-items: center;

		.circle-section {
			display: flex;
			width: 193px;
			height: 193px;
			background: #fff;
			border-radius: 50%;
			margin-bottom: 1.875rem;
			margin-bottom: 1.875rem;
			justify-content: center;

			.history-icon {
				position: relative;
				left: -5px;
			}
		}

		p {
			text-align: center;
		}
	}
}

.slider-section {
	font-size: 1rem;
	margin-bottom: 1.875rem;

	swiper-container {
		swiper-slide {
			padding: 4.375rem 4.375rem 7.5rem;
			background: var(--ion-black-primary);

			.qutoe-section {
				width: 98px;
				text-align: left;
				margin-right: 92px;

				img {
					transform: rotate(180deg);
				}
			}

			.message-section {
				width: calc(100% - 11.875rem);
				color: #fff;

				.customer-message {
					font-size: 1.7rem;
					font-weight: 400;
					font-style: italic;
					text-align: left;
				}

				.customer-name {
					font-size: 1.12rem;
					text-align: left;
					font-weight: 600;
					margin-top: 1.25rem;
				}
			}
		}
	}
}

//--- dynamic about us css ends here
.two-fa-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 19px 5px 24px;

	.modal-close-mobile {
		position: absolute;
		right: 18px;
		top: 20px;
		cursor: pointer;
	}

	.header-line {
		position: absolute;
		background-color: var(--ion-divider);
		width: 36px;
		height: 4px;
		top: 5px;
	}

	.two-fa-header {
		font-weight: 600;
		font-size: 1.25em;
		line-height: 110%;
		color: var(--ion-black-primary);
		border-bottom: 1px solid #dbdce2;
		width: 100%;
		padding-bottom: 19px;
		margin-bottom: 15px;
		text-align: center;
	}

	.filter-by-options {
		width: 100%;

		ion-list {
			overflow: auto;
			max-height: 250px;

			.label-checked {
				font-weight: 600;
			}

			ion-checkbox {
				--border-width: 0px;
				--checkmark-color: var(--ion-color-primary-contrast);
				--background: transparent;
				--background-checked: transparent;

			}
		}
	}
}

.secure-support-block {
	font-size: 0.875rem;
	color: var(--ion-black-primary);
	display: flex;
	justify-content: center;
	margin-bottom: 78px;

	.section {
		display: flex;
		align-items: center;

		img {
			width: 1.875rem;
			position: relative;
			top: 0.3125rem;
		}

		.right-side {
			display: flex;
			flex-direction: column;
			margin-left: 1.125rem;

			.title {
				font-size: 1.28rem;
				font-weight: 700;
			}

			.text {
				color: var(--ion-grey1);
				padding-top: 0.25rem;
			}
		}
	}

	.vertical-line {
		height: auto;
		width: 1px;
		background: #d5dadc;
		margin: 0 4.6875rem;
	}
}

[dir="rtl"] .secure-support-block {
	.section {
		.right-side {
			display: flex;
			flex-direction: column;
			margin-left: 0;
			margin-right: 1.125rem;
		}
	}
}

// custom drop down
.custom-dropdown {
	&::part(content) {
		width: 415px;
		border-radius: 0;
		max-height: 250px;
	}

	ion-list {
		padding: 0;
	}
}

// language drop down
.language-dropdown {
	top: -10px;

	ion-item {
		--padding-start: 0;
	}

	ion-label {
		font-size: 0.875rem !important;
		font-weight: 600;
		text-align: center;
	}

	ion-radio {
		position: absolute;
		width: 100%;
		height: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	&::part(content) {
		width: 7.5rem;
		border-radius: 0;
		margin-top: 0.625rem;
	}

	.item-radio-checked {
		--background: var(--ion-app-primary);
		--background-focused: var(--ion-app-primary);
		--background-focused-opacity: 0.2;
		--background-hover: var(--ion-app-primary);
		--background-hover-opacity: 0.12;
		--color: #fff;
	}

	ion-list {
		padding: 0;
	}
}

.vertical-align-text-top {
	vertical-align: text-top;
}

.font-bold {
	font-weight: bold;
}

.cursor-default {
	cursor: default !important;
}

// not found page
#notfound {
	background-image: url("assets/images/not-found-bg.jpg");
}

// button style
.custom-button {
	padding-top: 2.1875rem;

	ion-button {
		--background: var(--ion-app-primary);
		--border-radius: 0;
		--padding-start: 0;
		--padding-end: 0;
		width: 192px;
		height: 54px;
		font-weight: 600;
		font-size: 1rem;
		line-height: 1.125rem;
		letter-spacing: 0.4px;
		text-transform: uppercase;
		display: flex;
		align-items: center;

		.button-text {
			padding: 0 0.75rem 0 1.5625rem;
		}

		ion-icon {
			width: 1rem;
			height: 1rem;
		}
	}
}

.cta-button {
	padding-bottom: 0.9375rem !important;
	padding-top: 0;

	ion-button {
		margin-top: 0.75rem;
	}
}

.required-star {
	color: var(--ion-app-primary);
	padding-left: 0.3125rem;
}

.mobile-filters {
	display: none;
}

// register now
.register-block {
	background: url("assets/icon/register_bg.svg"), no-repeat;
	background-size: 100% 100%;
}

// Make menu relevant with header for solving width issue
app-header {
	position: relative;
	padding-bottom: 1.5625rem;
}

.home-page,
.detail-page,
.header-container {
	// padding: 0 5.625rem 0;
}

.mobile-menu {
	display: none;
}

.account-menu-header {
	display: none;
}

.ionic5-star-rating {
	button {
		width: 1.0625rem !important;
		height: 0.9375rem !important;
		margin-right: 0.25rem !important;
	}
}

[dir="rtl"] {
	.ionic5-star-rating {
		button {
			transform: scalex(-1);
		}
	}
}

.add-review-rating {
	button {
		margin-right: 1.75rem !important;
	}
}

.star-rating {
	button {
		width: 1.375rem !important;
	}
}

#addReviewModal {
	--width: 80vW;
	--height: auto;
}

#notifyModal {
	--width: 600px;
	--height: auto;
}

#termsWebModal {
	--width: 80%;
}

#orderDetailModal {
	--width: 830px;
	--height: 413px;
}

.header-md::after {
	background-image: none;
}

//google maps
.agm-map-container-inner {
	bottom: 1px !important;
}

.toast-custom-class {
	--max-width: 450px;
	--start: 67.5%;
}

.highlight {
	font-weight: bold !important;
}

.notify-me-modal {
	--height: unset !important;
}

@media screen and (max-width: 768px) {
	.cms-wrapper {
		padding-left: 0.9375rem !important;
		padding-right: 0.9375rem !important;
	}

	.mobile-view-listing {
		display: block;
	}

	.home-page,
	.detail-page,
	.header-container {
		// padding: 1.25rem 0 0 !important;
	}

	.detail-mobile {
		padding: 0 0 0 !important;
	}

	.mobile-filters {
		display: block;
	}

	.mobile-menu,
	.searchbar-container {
		display: block;
	}

	.return-order-footer,
	.return-request-footer {
		display: flex;
	}

	.side-menu {
		display: none;
	}

	.account-menu-header {
		display: block;
	}

	.account-details-header {
		display: none;
	}

	.account-details-page {
		padding: 0 !important;

		.set-padding {
			padding: 0 !important;
		}

		.container-row {
			.side-menu-section {
				width: 100% !important;
				padding-right: 0 !important;
			}
		}

		.app-header,
		.app-footer,
		.main-content {
			display: none;
		}
	}

	.mobile-menu {
		.menu-list {
			padding: 1.5rem 0 0 0.625rem !important;
		}
	}

	.addresses {
		.address-listing-header {
			display: none;
		}
	}

	#addEditAddressModal {
		--height: 90%;
		--width: 85%;
	}

	#sampleModal {
		--height: 350px;
		align-items: flex-end;
	}

	#addEditPaymentCardModal {
		--height: 100%;
		height: 100% !important;
	}

	#selectVariantModal {
		--width: 100% !important;
		--height: auto;
		--max-height: 500px;
		align-items: flex-end;

		.ion-page {
			--height: auto;
			--max-height: 500px;
		}
	}

	.native-input.sc-ion-input-md {
		padding: 0 !important;
	}

	#twoFaModal {
		--height: 332px;
		--width: 100%;
		align-items: flex-end;
	}

	#forgotPassword {
		align-items: flex-end;
	}

	#loginModal {
		ion-content::part(scroll) {
			overflow-y: hidden !important;
		}
	}

	.common-input {

		.sc-ion-input-md-s>[slot=start]:last-of-type,
		.sc-ion-input-md-s>[slot=end]:first-of-type {
			-webkit-margin-end: 0;
			margin-inline-end: 0;
			-webkit-margin-start: 0;
			margin-inline-start: 0;
		}

		ion-item {
			border-radius: 2px;
			align-items: center;

			ion-input {
				bottom: 0.3125rem;
			}

			.company-registration {
				bottom: 0 !important;
				margin-top: 0 !important;
			}

			ion-label {
				position: relative;
				bottom: 0.375rem;
				color: var(--ion-grey1);
			}

			.input-color {
				bottom: 0 !important;
			}

			.countrycode-label {
				position: absolute;
				top: 0.75rem;
				bottom: 0.875rem;
				left: 2.5rem;
			}

			.countrycode-label-edit {
				position: absolute;
				top: 0.75rem;
				bottom: 0.875rem;
				left: 2.5rem;
			}
		}

		ion-item.item-has-focus {
			border: 1px solid var(--ion-black-primary);
			box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
		}
	}

	.common-input-textarea {
		ion-item {
			border-radius: 2px;
			--min-height: 48px;
			height: 150px;
			align-items: center;

			ion-input {
				bottom: 0.3125rem;
			}

			.company-registration {
				bottom: 0 !important;
				margin-top: 0 !important;
			}

			ion-label {
				position: relative;
				bottom: 0.375rem;
				color: var(--ion-grey1);
			}

			.input-color {
				bottom: 0 !important;
			}

			.countrycode-label {
				position: absolute;
				top: 0.75rem;
				bottom: 0.875rem;
				left: 2.5rem;
			}
		}

		ion-item.item-has-focus {
			border: 1px solid var(--ion-black-primary);
			box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
		}
	}

	.password,
	.repeat-password {
		.password-icon {
			top: 1.1rem !important;
		}
	}

	.toast-custom-class {
		--max-width: 100%;
		--start: 2%;
	}

	.pagination-wrapper {
		display: none !important;
	}

	.listing-header-btn {
		display: none !important;
	}

	.listing-header {
		display: none !important;
	}

	.return-order-header,
	.return-request-header {
		display: block;
	}
}

@media screen and (max-width: 992px) {

	// content side padding
	.content-side-padding {
		padding-left: 0.625rem;
		padding-right: 0.625rem;
	}

	.my-wishlist-page {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.listing-table {
		display: none;
	}

	.mobile-view-listing,
	.searchbar-container {
		display: block;
	}

	.return-order-footer,
	.return-request-footer {
		display: flex;
	}

	.home-filter-div {
		display: none;
	}

	.home-page,
	.detail-page,
	.header-container {
		.home-container {
			padding-top: 0 !important;
		}
	}

	.search-header {
		padding: 0.8125rem 0.625rem !important;
		justify-content: center !important;

		.middle-block {
			width: 100% !important;
		}
	}

	.register-now {
		display: none;
	}

	.account-details-page {
		padding: 0.625rem !important;

		.app-header,
		.app-footer,
		.main-content {
			display: none;
		}
	}

	.side-menu {
		display: none;
	}

	.account-menu-header {
		display: block;
	}

	.mobile-menu {
		display: block;
	}

	.addresses {
		.address-listing-header {
			display: none;
		}
	}

	.pagination-wrapper {
		display: none !important;
	}

	.listing-header-btn {
		display: none !important;
	}

	.listing-header {
		display: none !important;
	}

	.return-order-header {
		display: block;
	}
}

@media screen and (max-height: 860px) {

	.register-Modal,
	.select-variant-modal {
		--height: 560px !important;
	}
}

@media screen and (max-height: 1130px) {

	.register-Modal,
	.select-variant-modal {
		--height: 850px;
	}

	#loginModal {
		--width: 560px;
	}
}

@media screen and (max-width: 1230px) {
	.my-wishlist-page {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.dashboard-status-btn {
		min-width: unset !important;
	}

	.upper-part-watchlist {
		.select-btn-block {
			.select-block {
				ion-select {
					border: 1px solid #d5dadc;
					padding: 0.5625rem 0.3125rem;
					width: 220px;
					margin-left: 0.625rem;
				}
			}

			.add-cart-button {
				ion-button {
					width: 206px;
				}
			}
		}
	}
}

@media screen and (max-width: 1368px) {

	.home-page,
	.detail-page,
	.header-container {
		// padding: 0.8125rem 1.875rem 0;

		.home-container {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	.bread-crumbs {
		margin: 1.125rem 4.125rem 0.8125rem !important;
	}

	.detail-page {
		padding: 0.8125rem 7.5rem 0 !important;
	}

	// container-row padding on small device
	.set-padding {
		padding-left: 0.9375rem !important;
		padding-right: 0.625rem !important;
	}

	.cart-Modal::part(content) {
		top: 4.6875rem !important;
		position: absolute !important;
		right: 1.875rem !important;
		left: unset !important;
		box-shadow: 0 1.25rem 7.5rem #00000026;
		z-index: 99;
	}

	[dir="rtl"] .cart-Modal::part(content) {
		direction: rtl;
		top: 4.6875rem !important;
		position: absolute !important;
		left: 1.875rem !important;
		right: unset !important;
		box-shadow: 0 1.25rem 7.5rem #00000026;
		z-index: 99;
	}
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
	#loginModal {
		padding-top: 5rem;
		align-items: start;
		--height: 515px;
	}

	#twoFaModal {
		padding-top: 5rem;
		align-items: start;
	}

	#addReviewModal,
	#notifyModal {
		padding-top: 5rem;
		align-items: start;
	}

	#orderDetailModal {
		padding-top: 5rem;
		align-items: start;
	}

	.register-Modal {
		--width: 560px;
	}

	.select-variant-modal {
		--width: 60%;
	}
}

@media screen and (min-width: 768px) and (max-height: 768px) {
	#loginModal {
		--height: 515px;
		--width: 562px;
	}

	.register-Modal {
		--width: 562px;
	}

	.select-variant-modal {
		--width: 60%;
	}
}

@media screen and (max-width: 1575px) {
	.detail-page {
		padding: 0 8.125rem;

		.detail-page-col {
			padding-left: 2.5rem !important;
		}
	}
}

@media screen and (min-width: 2200px) {

	.home-page .detail-page,
	.header-container {
		max-width: 2200px;
	}
}

.error-msg {
	font-size: 0.9875rem;
	color: var(--ion-color-danger);
}

.error-msg-green {
	font-size: 0.9875rem;
	color: var(--ion-color-success-shade);
}


.required-text {
	color: var(--ion-app-primary);
}

//color CSS
.ion-focused.label-floating.sc-ion-label-md-h,
.ion-focused .label-floating.sc-ion-label-md-h,
.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h,
.item-has-placeholder.sc-ion-label-md-h:not(.item-input).label-floating,
.item-has-placeholder:not(.item-input) .label-floating.sc-ion-label-md-h,
.item-has-value.label-floating.sc-ion-label-md-h,
.item-has-value .label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
	color: var(--ion-grey1);
}

.color-app-grey {
	color: var(--ion-grey1);
}

.color-app-grey2 {
	color: var(--ion-grey2);
}

.color-app-black {
	color: var(--ion-black-primary);
}

.color-white {
	color: white;
}

.color-primary {
	color: var(--ion-app-primary);
}

.color-app-orange {
	color: var(--ion-orange-primary);
}

.bg-primary {
	background: var(--ion-app-primary);
}

.bg-grey {
	background: var(--ion-grey2);
}

.bg-dark-grey {
	background: var(--ion-dark-grey);
}

.bg-light-green {
	background: var(--ion-app-light-green);
}

.bg-light-orange {
	background: var(--ion-app-light-orange);
}

//ltr css
.edit-div {
	position: absolute;
	top: 0.9375rem;
	right: 0.9375rem;
	left: unset;
}

.right-30 {
	right: 1.875rem;
}

.p-35-190 {
	padding: 2.1875rem 8.875rem;
}

.p-25-60 {
	padding: 1.5625rem 3.75rem;
}

.filter-checkbox-list {
	ion-checkbox::part(container) {
		padding: 0.0625rem !important;
		height: 1.5rem;
		width: 1.5rem;
	}
}

//rtl css

[dir="rtl"] .edit-div {
	position: absolute;
	top: 0.9375rem;
	left: 0.9375rem;
	right: unset;
}

[dir="rtl"] .right-30 {
	left: 1.875rem;
}

@media screen and (min-width: 768px) and (min-height: 768px) {
	.sample-modal {
		.ion-page {
			--height: 400px;
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}

.language-select {
	ion-select::part(icon) {
		margin-left: 1.4375rem;
	}
}

.language-select-rtl {
	ion-select::part(icon) {
		margin-right: 1.4375rem;
	}
}

.new-address-modal {
	ion-select {
		padding-top: 0;
		padding-bottom: 0;
		margin: 0;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 100%;
	}

	.countrycode-label-edit {
		position: absolute;
		top: 31.5px;
	}

	.option-selected {
		height: 20px !important;
		min-height: 20px !important;
		max-height: 20px !important;
	}

	ion-label {
		color: var(--ion-grey1) !important;
		padding: 0;
		line-height: 1.4rem;
		max-height: 22px;
		margin-bottom: 0.4375rem !important;
	}

	.static-label {

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

	.static-label-mobile {
		text-transform: uppercase;
		position: absolute;
		top: 12.5px;
		margin-top: 0px !important;
		color: var(--ion-grey1) !important;

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

	ion-select::part(icon) {
		background-color: var(--ion-black-primary);
		-webkit-mask-image: url("/assets/icon/down-arrow-dark.svg");
		mask-image: url("/assets/icon/down-arrow-dark.svg");
		color: transparent !important;
		opacity: 1 !important;
		width: 1.0625rem !important;
		height: 1.0625rem !important;
		// margin-left: 0.4375rem;
		position: absolute;
		right: 0;
		top: 1.2rem;
		margin-right: 1.375rem;
	}

	.option-selected::part(icon) {
		background-color: var(--ion-black-primary);
		-webkit-mask-image: url("/assets/icon/down-arrow-dark.svg");
		mask-image: url("/assets/icon/down-arrow-dark.svg");
		color: transparent !important;
		opacity: 1 !important;
		width: 1.0625rem !important;
		height: 1.0625rem !important;
		// margin-left: 0.4375rem;
		position: absolute;
		right: 0;
		top: 0;
		margin-right: 1.375rem;
	}


}

.new-address-modal-arabic {
	ion-select {
		padding-top: 0;
		padding-bottom: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	.countrycode-label-edit {
		position: absolute;
		top: 31.5px;
	}

	.option-selected {
		height: auto !important;
	}

	ion-label {
		color: var(--ion-grey1) !important;
		padding: 0;
		line-height: 1.4rem;
		max-height: 22px;
		margin-bottom: 0.4375rem !important;
	}

	.static-label {
		text-transform: uppercase;
		position: absolute;
		top: 17px;
		margin-top: 0px !important;
		color: var(--ion-grey1) !important;

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

	.static-label-mobile {
		text-transform: uppercase;
		position: absolute;
		top: 12.5px;
		margin-top: 0px !important;
		color: var(--ion-grey1) !important;

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

	ion-select::part(icon) {
		background-color: var(--ion-black-primary);
		-webkit-mask-image: url("/assets/icon/down-arrow-dark.svg");
		mask-image: url("/assets/icon/down-arrow-dark.svg");
		color: transparent !important;
		opacity: 1 !important;
		width: 1.0625rem !important;
		height: 1.0625rem !important;
		// margin-left: 0.4375rem;
		position: absolute;
		left: 1.1875rem;
		top: 1.2rem;
	}

	.option-selected::part(icon) {
		background-color: var(--ion-black-primary);
		-webkit-mask-image: url("/assets/icon/down-arrow-dark.svg");
		mask-image: url("/assets/icon/down-arrow-dark.svg");
		color: transparent !important;
		opacity: 1 !important;
		width: 1.0625rem !important;
		height: 1.0625rem !important;
		// margin-left: 0.4375rem;
		position: absolute;
		left: 1.1875rem;
		top: 1.7rem !important;
	}
}

#myaccount-modal {
	.ion-page {
		justify-content: unset;
	}
}

.sort-by-dropdown {
	ion-select::part(icon) {
		background-image: url("/assets/icon/downTriangle.svg") no-repeat center;
		opacity: 1 !important;
	}
}

.company-registration {
	ion-label {
		margin-bottom: 0;
	}
}

.paginator {
	.ngx-pagination {
		padding: 0;
		font: normal normal bold 0.875rem /21px Noto Sans;

		li {
			color: var(--ion-black-primary);
			opacity: 0.6;
		}

		li:not(.current, .pagination-next, .pagination-previous) {
			background: var(--ion-color-primary-contrast);
		}

		.current {
			background: var(--ion-app-primary);
			color: var(--ion-color-primary-contrast);
			opacity: 1;
		}

		a,
		.current {
			padding: 0.29rem 0.68rem;
		}

		a:hover,
		.ngx-pagination button:hover {
			background: var(--ion-color-primary-contrast);
		}

		.pagination-previous a:before,
		.pagination-previous.disabled:before {
			content: url("/assets/icon/prev_arrow.svg");
			top: 0.125rem;
			position: relative;
		}

		.pagination-next a:after,
		.pagination-next.disabled:after {
			content: url("/assets/icon/next_arrow.svg");
			top: 0.125rem;
			position: relative;
		}

		.pagination-previous a,
		.pagination-previous.disabled {
			padding: 1rem 0.7rem;
		}

		.pagination-next a,
		.pagination-next.disabled {
			padding: 1rem 0.7rem;
		}
	}
}

.sort-by-item {
	ion-select::part(icon) {
		display: none;
	}

	ion-icon {
		width: 1rem;
		height: 1rem;
	}
}

.cms-wrapper {
	color: var(--ion-black-primary);
	font-size: 1rem;
	font-weight: 700;
	margin: 0 0 1.375rem;
	padding: 0 4.4375rem;
	margin-bottom: 2.5rem;

	.swiper-container-horizontal>.swiper-pagination-bullets {
		bottom: 3.1875rem;
		left: 0;
		width: 100%;
		text-align: left;
		margin-left: 16.25rem;
	}

	.swiper-pagination-bullet {
		width: 1.125rem;
		height: 1.125rem;
		background: var(--ion-black-primary) !important;
		border: 1px solid #fff;
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
		background: #007aff !important;
		border: 1px solid #007aff;
	}

	.video-section {
		margin-bottom: 1.875rem;

		.thumbnail-part {
			background: url("assets/images/slider.jpg");
			width: 100%;
			height: 614px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-size: cover;

			.circle-block {
				width: 113px;
				padding: 1.125rem;
				height: 113px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				background: #fff;

				.play-icon {
					width: 100%;
					cursor: pointer;
				}
			}
		}

		video {
			width: 100%;
			height: 614px;
			background: #888;
		}
	}

	.page-heading {
		font-size: 2.6rem;
		margin-bottom: 1.5625rem;
	}

	hr {
		background: #d5dadc;
		margin-bottom: 1.25rem;
	}

	.heading,
	.main-heading {
		margin: 0 0 0.8125rem;
		font-weight: 700;
	}

	.main-heading {
		font-size: 1.75rem;
	}

	.heading {
		font-size: 1.12rem;
	}

	p,
	ul {
		margin: 0 0 1.875rem;
		color: var(--ion-grey1);
		font-size: 1rem;
		font-weight: 400;
	}

	.note {
		font-size: 1rem;
		color: var(--ion-aqua);
		font-weight: 400;
	}

	ion-accordion-group {
		margin-bottom: 3.0625rem;

		ion-accordion {
			ion-item {
				margin-bottom: 0.625rem;
			}

			ion-label {
				font-size: 1.12rem;
				font-weight: 700;
			}

			ion-item::after {
				position: absolute;
				right: 0.75rem;
				top: 0.25rem;
				z-index: 999;
				content: "+";
				color: var(--ion-app-primary);
				font-size: 1.75rem;
				font-weight: 600;
				cursor: pointer;
			}
		}

		.accordion-expanded {
			border: 1px solid var(--ion-divider);
			margin-bottom: 0.625rem;

			ion-item {
				&::part(native) {
					background-color: #fff;
				}

				ion-label {
					border-bottom: 1px solid var(--ion-divider);
					padding-bottom: 1rem;
				}
			}

			ion-item::after {
				position: absolute;
				right: 0.75rem;
				top: 1.5rem;
				z-index: 999;
				color: #38c2d4;
				width: 0.8125rem;
				height: 3px;
				background: #38c2d4;
				content: "";
				cursor: pointer;
			}
		}
	}
}

[dir="rtl"] {
	ion-accordion-group {
		ion-accordion {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}
		}

		.accordion-expanded {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}
		}
	}

	.buttons-container {
		.connect-buttons {

			.arrowIcon {
				position: absolute;
				right: unset !important;
				left: 0.8125rem !important;
			}
		}
	}
}

[dir="rtl"] {
	.detail-page .detail-page-container .detail-page-description .add-cart-container .add-cart-button ion-button .cart-icon {
		position: absolute;
		left: unset !important;
		right: 0 !important;
	}

}

.paginator-icon-rotate {
	.ngx-pagination {

		.pagination-previous a:before,
		.pagination-previous.disabled:before {
			content: url("/assets/icon/prev_arrow.svg");
			transform: rotate(180deg);
			top: -2px;
			position: relative;
		}

		.pagination-next a:after,
		.pagination-next.disabled:after {
			content: url("/assets/icon/next_arrow.svg");
			transform: rotate(180deg);
			top: -2px;
			position: relative;
		}
	}
}

// below the tab size
@media screen and (max-width: 550px) {
	ion-toolbar {
		// --ion-statusbar-padding: 20px;
		// padding-top: var(--ion-safe-area-top) !important;
	}

	ion-header ion-toolbar:first-of-type {
		padding-top: 0px;
	}

	ion-popover {
		--offset-y: 1.0625rem;
		--width: 100%;
		top: -45px;
	}
}

// below the tab size
@media screen and (max-width: 767px) {
	.cms-wrapper {
		.swiper-container-horizontal>.swiper-pagination-bullets {
			text-align: center;
			margin-left: unset;
		}
	}

	.home-page,
	.detail-page,
	.header-container {
		padding: 0 !important;
	}

	.top-slider {
		padding-bottom: 1rem !important;
	}

	.new-address-modal {
		ion-select {
			padding-bottom: 0.625rem;
		}

		ion-label {
			margin-bottom: 0 !important;
		}
	}

	.new-address-modal-arabic {
		ion-select {
			padding-bottom: 0.625rem;
		}

		ion-label {
			margin-bottom: 0 !important;
		}
	}

	.desktop-visibility {
		display: none;
	}

	.mobile-visibility {
		display: block;
	}

	.static-label {
		margin-bottom: 0.025rem;
	}

	.desktop-visibility-in-flex {
		display: none;
	}

	.mobile-visibility-in-flex {
		display: flex;
	}

	.search-header {
		.left-block {
			display: none !important;
		}

		.right-block {
			display: none !important;
		}

		.mobile-user-block-ltr {
			display: block;
			padding: 0.3125rem 25rem 0 0 0.625rem;
			cursor: pointer;
		}

		.mobile-user-block-rtl {
			display: block;
			padding-right: 1rem;
			cursor: pointer;
		}
	}

	.main-header {
		display: none !important;
	}

	.app-footer {
		display: none;
	}

	.registration-pass {
		width: 100% !important;
	}
}

.sortby-dropdown {
	&::part(content) {
		width: 13rem;
	}
}

.footer-md::before {
	background-image: none;
}

.registration-pass {
	width: 92%;
}

.container {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
	margin-right: auto !important;
	margin-left: auto !important;
	overflow-x: hidden;
	max-width: 100%;

	//commenting beacuse of home ui changes here
	// @media (min-width: 1800px) {
	//     max-width: 1600px;
	// }
}

.more-info-tooltip {
	position: sticky;
	right: -27px;
	top: 25%;

	img {
		cursor: pointer;
	}
}

.more-info-tooltip .more-info-tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: 1.75rem;
	right: 0%;
	font-size: 0.875rem;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
}

.more-info-tooltip .more-info-tooltiptext-left {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: -6px;
	right: 0;
	font-size: 0.875rem;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
	left: 25px;
}

[dir="rtl"] .more-info-tooltip .more-info-tooltiptext-left {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: -6px;
	left: 0;
	font-size: 0.875rem;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
	right: 25px;
}

.more-info-file-tooltip {
	width: fit-content !important;
}

.more-info-tooltip:hover .more-info-tooltiptext,
.more-info-tooltip:hover .more-info-tooltiptext-left {
	visibility: visible;
}

.error-txt {
	color: var(--payment-fail);
	font-size: 1rem;
}

#wishlistModal {
	--height: 350px;
	align-items: flex-end;

}

[dir="rtl"] {
	* {
		text-transform: none !important;
		text-transform: none !important;
	}

	text,
	ion-label,
	p,
	span,
	ion-title,
	div {
		text-transform: none !important;
		text-transform: none !important;
	}


	.side-menu {
		border-left: 1px solid var(--ion-gray4) !important;
		border-right: 0 !important
	}

	.ion-text-right {
		text-align: left !important;
	}
}

ion-toolbar {
	ion-title {
		width: auto;
		display: inherit;
	}
}

// .select-popover,
// .popover-desktop {
//     width: 70%;
//     --width: 70%;
//     min-width: 70%;
//     --min-width: 70%;
// }

.rfq-dropdown {
	top: -10px;

	.popover-wrapper {
		--width: 100%;
	}

	ion-item {
		--padding-start: 16px;
		--padding-end: 16px;
	}

	ion-label {
		font-size: 1rem !important;
		font-weight: 600;
		text-align: left;
	}

	ion-radio {
		position: absolute;
		width: 100%;
		height: 100%;
	}



	&::part(content) {
		--width: 65%;
		// --max-width: unset !important;
		border-radius: 0;
		margin-top: 0.625rem;
	}

	.item-radio-checked {
		--background: var(--ion-app-primary);
		--background-focused: var(--ion-app-primary);
		--background-focused-opacity: 0.2;
		--background-hover: var(--ion-app-primary);
		--background-hover-opacity: 0.12;
		--color: #fff;
	}

	ion-list {
		padding: 0;
	}
}

.d-flex {
	display: flex;
}


ion-select {
	--padding-start: 16px;
	--inline-padding-end: 16px;
}

.ion-page-hidden {
	pointer-events: none;
	visibility: hidden;
}